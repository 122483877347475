
import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "routes";
import Privacy from "PrivacyPolicy";
import TermsCondition from "TermsCondition";
import StoryDoc from "pages/LandingPages/StoryDoc";
import Pageheaders from "pages/Presentation/sections/sweetComponent/sweetHeader";
import ReactGA from 'react-ga4';
import AboutUs from "pages/LandingPages/AboutUs";
import Disclaimer from "Disclaimer";
import ContactUs from "pages/LandingPages/ContactUs";
import Author from "pages/LandingPages/Author";
ReactGA.initialize('G-FZ79Y9F8B2');


export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/SweetExpert" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/SweetExpert" />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/StoryDoc" element={<StoryDoc />} />
        <Route path="/SweetExpertDetail/:id" element={<Pageheaders />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Author" element={<Author />} />
      </Routes>
    </ThemeProvider>
  );
}
