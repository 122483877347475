import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { useParams } from "react-router-dom";

// Images
import bgImage from "assets/images/sweetLanded.png";


import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore/lite";
import cong from "utils";
import { useEffect, useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import ExampleCard from "pages/Presentation/components/ExampleCard";
import { Opacity } from "@mui/icons-material";
import zIndex from "@mui/material/styles/zIndex";

function Pageheaders() {
  const { id } = useParams(); //

  const [sweetDetail, setSweetDetail] = useState(null);

  useEffect(() => {
    // Initialize the Firebase database with the provided configuration
    const database = getFirestore(cong);
    const auth = getAuth(cong);
    signInWithEmailAndPassword(auth, "web@gmail.com", "Hujh785sdjfb!7357")
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        fetchData();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });

    // Function to fetch data from the database
    const fetchData = async () => {
      const q = query(
        collection(database, "Experts"),
        where("id", "==", id.substring(2))
      );
      getDocs(q).then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          console.log('doc.data()', doc.data());
          setSweetDetail(doc.data());
          
        });
      });
    };

    // Fetch data when the component mounts
  }, []);


  
  
  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random()
        * (max - min + 1)) + min;
};

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "*",
          label: "free download",
          color: "info",
        }}
        sticky
      />
      
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          display: "grid",
          placeItems: "center",
        }}
      >
       <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          position: 'absolute',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
      />
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              px={{ xs: 12, lg: 12 }}
            >
              {sweetDetail?.businessName}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              fontSize={14}
              px={{ xs: 12, lg: 12 }}
            >
              Area: {sweetDetail?.searchArea?.join(', ')}
            </MKTypography>
            
            <MKTypography
              variant="body2"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12}}
              mt={1}
            >
             {sweetDetail?.description}
            </MKTypography>
          </Grid>
        </Container>

       
      </MKBox>
      
      
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
        style={{alignItems: 'center', alignSelf: 'center'}}
      >
        
         <Container>
          <Grid container spacing={4} mt={10}>
          <Grid item xs={3} lg={1}></Grid>
          {sweetDetail?.photos.map(( image, index) => (
            <Grid item xs={6} lg={2} key={index}>
            <Grid item sx={{ mb: 2 }} key={index}>
            <ExampleCard image={image} name={''} count={null} pro={false} />
            </Grid>
            </Grid>
          ))}
           
          </Grid>
        </Container>
        <Information />
        
       
        
      
        {/* <Pages /> */}
        <Container sx={{ mt: 0 }}>
          <BuiltByDevelopers />
        </Container>
        {/* <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Getting Started"
                description="Check the possible ways of working with our product and the necessary files for building your own project."
                action={{
                  type: "external",
                  route: "*",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="Plugins"
                description="Get inspiration and have an overview about the plugins that we used to create the Material Kit."
                action={{
                  type: "external",
                  route: "*",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Components"
                description="Material Kit is giving you a lot of pre-made components, that will help you to build UI's faster."
                action={{
                  type: "external",
                  route: "*",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container> */}
        <Testimonials />
        <Download />
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                lg={5}
                ml="auto"
                sx={{ textAlign: { xs: "center", lg: "left" } }}
              >
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                We deliver the best Service Provider
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                {/* <MKSocialButton
                  component="a"
                  href="https://twitter.com"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton> */}
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/profile.php?id=61561938213092"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                {/* <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-instagram" />
                  &nbsp;instagram
                </MKSocialButton> */}
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Pageheaders;
