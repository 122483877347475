import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
        <MKTypography variant="h3" color="black" mb={3} opacity={1}>
          "Sweet Creations: Crafting Homemade Delights with Passion and Purpose"
            </MKTypography>
        <MKTypography variant="body2" color="black" mb={3} opacity={0.8}>
        Welcome to Sweet Creations, where every bite is a celebration of homemade goodness and best expertise. At Sweet Creations, we believe in the magic of homemade item and the joy they bring to every time.


            </MKTypography>
            <MKTypography variant="body2" color="black" mb={3} opacity={0.8}>
            Our journey began with a simple desire to share your flavors of homemade sweets with our community. Fueled by a passion for excellence and a commitment to quality, we embarked on a mission to bring the warmth and comfort of homemade delicacies to discerning palates.
            </MKTypography>
            <MKTypography variant="body2" color="black" mb={3} opacity={0.8}>
            Led by our founder, Sweet Expert service is more than just a business's a celebration of tradition, creativity, and empowerment. Drawing inspiration from our humble beginnings, we've cultivated a culture of innovation and collaboration, where every member of our team is valued for their unique talents and contributions.
            </MKTypography>
            <MKTypography variant="body2" color="black" mb={3} opacity={0.8}>
            At Sweet expert service, we take pride in our commitment to using only the finest ingredients, sourced locally and responsibly. From handcrafted delicacies to timeless classics, each sweet treat is lovingly prepared with attention to detail and a dash of creativity.
            </MKTypography>
            <MKTypography variant="body2" color="black" mb={3} opacity={0.8}>
            But our dedication to excellence extends beyond the kitchen. As advocates for community empowerment, we're proud to provide opportunities for home-based entrepreneurs to showcase their talents and pursue their passion for cooking. Through our platform, we're just provide best sweets services who's provide best sweets empowering individuals to turn their dreams into reality.
            </MKTypography>
            <MKTypography variant="body2" color="black" mb={3} opacity={0.8}>
            Whether you're celebrating a special occasion or simply taste of home, Sweet expert service is here to delight your senses and satisfy your sweet tooth. Join us on our journey as we continue to spread business, one homemade treat at a time.
            </MKTypography>
            <MKTypography variant="body2" color="black" mb={3} opacity={0.8}>
            Indulge in the sweetness of life with Sweet expert service where every bite tells a story, and every moment is a reason to celebrate.
            </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
