import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      borderRadius={5}
      py={6}
      mx={1.2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              PRIVACY POLICY
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
            Last updated: 31st January, 2022
            </MKTypography>
            <MKTypography mt={6} variant="body2" color="white" opacity={1}>
            Welcome to Sweet Expert Service Company’s privacy policy (“
            <b>Privacy Policy</b>” or “<b>Policy</b>”).
            </MKTypography>

            <MKTypography mt={6} variant="body2" color="white" opacity={1}>
            <b>SweetExpertService India Private Limited </b>and its affiliates (collectively, “<b>SweetExpertService Company</b>”, <b>“we”</b> or <b>“us”</b>) are engaged in the business of providing only app based solutions to facilitate connections between customers that seek specific services and service Expert that offer these services. This Policy outlines our practices in relation to the collection, storage, usage, processing, and disclosure of personal data that you have consented to to share with us when you access, use, mobile application ‘Sweet Expert Service Company’ (collectively, <b>“Platform”</b>) or avail services that SweetExpertService Company offers you on or through the Platform (collectively, the <b>“Services”</b>).In this Policy, the services offered to you by service Expert on or through the Platform are referred to as <b>“Expert Services”</b>.
</MKTypography>
            <MKTypography mt={2} variant="body2" color="white" opacity={1}>
At SweetExpertService Company, we are committed to protecting your personal data and respecting your privacy. In order to provide you with access to the Services or the Expert Services, we have to collect and otherwise process certain data about you. This Policy explains how we process and use personal data about you.
</MKTypography>
            <MKTypography mt={2} variant="body2" color="white" opacity={1}>
Please note that unless specifically defined in this Policy, capitalized terms shall have the same meaning ascribed to them in our Terms and Conditions, available at https://sweetexpertservice.com/terms <b>(“Terms”)</b>. Please read this Policy in consonance with the Terms.
</MKTypography>
            <MKTypography mt={2} variant="body2" color="white" opacity={1}>
By using the Services, you confirm that you have read and agree to be bound by this Policy and consent to the processing activities described under this Policy.
</MKTypography>
            <MKTypography mt={2} variant="body2" color="white" opacity={1}>
Please refer to Section 1 to understand how the terms of this Policy apply to you.
</MKTypography>
 
 <MKTypography mt={4} variant="h6" color="white" opacity={1}>
1. BACKGROUND AND KEY INFORMATION
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) <u>How this Policy applies:</u>
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
This Policy applies to individuals who access or use the Services or otherwise avail the Expert Services. For the avoidance of doubt, references to “you” across this Policy are to an end user that uses the Platform.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
By using the Platform, you consent to the collection, storage, usage, and disclosure of your personal data, as described in and collected by us in accordance with this Policy.
</MKTypography>

<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) <u>Review and Updates:</u>
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
We regularly review and update our Privacy Policy, and we request you to regularly review this Policy. It is important that the personal data we hold about you is accurate and current. Please let us know if your personal data changes during your relationship with us.
</MKTypography>

<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(c) <u>Third-Party Services:</u>
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
The Platform may include links to third-party websites, plug-ins, services, and applications <b>(“Third-Party Services”)</b>. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We neither control nor endorse these Third-Party Services and are not responsible for their privacy statements. When you leave the Platform or access third-party links through the Platform, we encourage you to read the privacy policy of such third-party service providers.
</MKTypography>

<MKTypography mt={4} variant="h6" color="white" opacity={1}>
2. PERSONAL DATA THAT WE COLLECT
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) We collect different types of personal data about you. This includes, but is not limited to:
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(i) <b>Contact Data</b>, such as your mailing or home address, location, email addresses, and mobile numbers.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(ii) <b>Identity and Profile Data,</b> such as your name, username or similar identifiers, photographs.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(iii) <b>Marketing and Communications Data,</b> such as your address, email address, information posted in service requests, offers, wants, feedback, comments, pictures and discussions in our blog and chat boxes, responses to user surveys and polls, your preferences in receiving marketing communications from us and our third parties, and your communication preferences. We also collect your chat when you communicate with service Expert through the Platform.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(iv) <b>Technical Data,</b> which includes your IP address, browser type, internet service provider, details of operating system, access time, page views, device ID, device type, frequency of visiting our mobile application activity, clicks, date and time stamps, location data, and other technology on the devices that you use to access the Platform.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(v) <b>Transaction Data,</b> such as details of the Services or Expert Services you have availed, as of now we can not implement payment methods in our platform yet.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(vi) <b>Usage Data,</b> which includes information about how you use the Services and Expert Services, your activity on the Platform, booking history, user taps and clicks, user interests, time spent on the Platform, details about user journey on the mobile application, and page views.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) We also collect, use, and share aggregated data such as statistical or demographic data for any purpose. Aggregated data could be derived from your personal data but is not considered personal data under law as it does not directly or indirectly reveal your identity. However, if we combine or connect aggregated data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Policy.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(c) <u>What happens if I refuse to provide my personal data?</u>
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
Where we need to collect personal data by law, or under the terms of a contract (such as the Terms), and you fail to provide that data when requested, we may not be able to perform the contract (for example, to provide you with the Services). In this case, we may have to cancel or limit your access to the Services.
</MKTypography>

<MKTypography mt={4} variant="h6" color="white" opacity={1}>
3. HOW DO WE COLLECT PERSONAL DATA?
</MKTypography>

<MKTypography mt={1} variant="body2" color="white" opacity={1}>
We use different methods to collect personal data from and about you including through:
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) <b>Direct Interactions.</b> You provide us your personal data when you interact with us. This includes personal data you provide when you:
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(i) create an account or profile with us;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(ii) use others Services or provide service to customer carry out other activities in connection with the Services;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(iii) enter a promotion, user poll, or online surveys;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(iv) request marketing communications to be sent to you; or
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(v) report a problem with the Platform and/or our Services, give us feedback or contact us.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) <b>Automated technologies or interactions.</b> Each time you visit the Platform or use the Services, we will automatically collect Technical Data about your equipment, browsing actions, and patterns. We collect this personal data by using server logs, and other similar technologies.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(c) <b>Third parties or publicly available sources.</b> We will receive personal data about you from various third parties:
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(i) Technical data from analytics providers such as Facebook;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(ii) Identity and profile-related Data and Contact Data from service Expert, publicly available sources, etc.;
</MKTypography>
 
 <MKTypography mt={4} variant="h6" color="white" opacity={1}>
4. HOW DO WE USE YOUR PERSONAL DATA?
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) We will only use your personal data when the law allows us to. Most commonly, we will use your personal data where we need to provide you with the Services, enable you to use the Expert Services, or where we need to comply with a legal obligation. We use your personal data for the following purposes:
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(i) to verify your identity to register you as a user, and create your user account with us on the Platform;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(ii) to provide the Services to you;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(iii) to enable the provision of Expert Services to you;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(iv) to monitor trends and personalize your experience;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(v) to improve the functionality of our Services based on the information and feedback we receive from you;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(vi) to improve customer service to effectively respond to your Service requests and support needs;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(vii) to Provide your service information to the other customer it’s up to you which type of data you have to share 
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(viii) to send periodic notifications to manage our relationship with you including to notify you of changes to the Services, send you information and updates pertaining to the Services you have availed, and to receive occasional company news and updates related to us or the Services;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(ix) to assist with the facilitation of the Expert Services offered to you, including to send you information and updates about the Expert Services you have availed;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(x) to market and advertise the Services to you;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(xi) to comply with legal obligations;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(xii) to administer and protect our business and the Services , including for troubleshooting, data analysis, system testing, and performing internal operations;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(xiii) to improve our business and delivery models;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(xiv) to perform our obligations that arise out of the arrangement we are about to enter or have entered with you;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(xv) to enforce our Terms; and
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(xvi) to respond to court orders, establish or exercise our legal rights, or defend ourselves against legal claims.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) You agree and acknowledge that by using our Services and creating an account with us on the Platform, you authorize us, our service Expert, associate partners, and affiliates to contact you via email, phone, or otherwise. This is to provide the Services to you and ensure that you are aware of all the features of the Services and for related purposes.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(c) You agree and acknowledge that any and all information pertaining to you, whether or not you directly provide it to us (via the Services or otherwise), including but not limited to personal correspondence such as emails, instructions from you, etc., may be collected, compiled, and shared by us in order to render the Services to you. This may include but not be limited to service Experts who provide or seek to provide you with Expert Services, vendors, social media companies, third-party service providers, storage providers, data analytics providers, consultants, lawyers, and auditors. We may also share this information with other entities in the SweetExpertService Company group in connection with the above-mentioned purposes.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(d) You agree and acknowledge that we may share data without your consent, when it is required by law or by any court or government agency or authority to disclose such information. Such disclosures are made in good faith and belief that it is reasonably necessary to do so for enforcing this Policy or the Terms, or in order to comply with any applicable laws and regulations.
</MKTypography>

<MKTypography mt={4} variant="h6" color="white" opacity={1}>
5. COOKIES
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) Cookies are small files that a site or its service provider transfers to your device’s hard drive through your web browser (if you permit it to) that enables the sites or service providers’ systems to recognise your browser and capture and remember certain information.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) We use cookies to help us distinguish you from other users of the Platform, understand and save your preferences for future visits, keep track of advertisements and compile aggregate data about site traffic and site interaction so that we can offer you a seamless user experience. We may contact third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(c) Additionally, you may encounter cookies or other similar devices on certain pages of the Platform that are placed by third parties. We do not control the use of cookies by third parties. If you send us personal correspondence, such as emails, or if other users or third parties send us correspondence about your activities or postings on the Platform, we may collect such information within a file specific to you.
</MKTypography>

 
<MKTypography mt={4} variant="h6" color="white" opacity={1}>
6. DISCLOSURES OF YOUR PERSONAL DATA
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) We may share your personal data with third parties set out below for the purposes set out in Section 4:
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(i) Service Expert to enable them to provide you with Experts Services;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
</MKTypography>
 
 <MKTypography mt={4} variant="h6" color="white" opacity={1}>
7. YOUR RIGHTS IN RELATION TO YOUR PERSONAL DATA
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) <u>Access and Updating your Personal Data</u>:You hereby warrant that all personal data that you provide us with is accurate, up-to-date, and true. When you use our Services, we make best efforts to provide you with the ability to access and correct inaccurate or deficient data, subject to any legal requirements. You can request SweetExpertService Company for a copy of your personal data by sending an email to info@sweetexpertservice.com. SweetExpertService Company may take up to 7 (seven) working days to respond to such a request.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) <u>Opting-out of Marketing and Promotional Communications</u>:When we send you marketing and promotional content through email, we make best efforts to provide you with the ability to opt-out of such communications by using the opt-out instructions provided in such emails. You understand and acknowledge that it may take us up to 10 (Ten) business days to give effect to your opt-out request. Please note that we may still send you emails about your user account or any Services you have requested or received from us.
</MKTypography>

<MKTypography mt={4} variant="h6" color="white" opacity={1}>
8. DELETION OF ACCOUNT AND PERSONAL DATA 
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) Notwithstanding anything contained in the Terms, you may delete your account as well as your personal data stored with SweetExpertService Company by sending an email to info@sweetexpertservice.com. SweetExpertService Company may take up to 7 (seven) working days to process your request. Once your account is deleted, you will lose access to all Services. And Add your Expertise For avoidance of doubt, it is hereby clarified that all data with respect to transactions performed by you on the Platform will be retained in accordance with applicable law.
</MKTypography>

<MKTypography mt={4} variant="h6" color="white" opacity={1}>
9. TRANSFERS OF YOUR PERSONAL DATA
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) We comply with applicable laws in respect of storage and transfers of personal data. As a part of your use of the Services, the information and personal data you provide to us may be transferred to and stored in countries other than the country you are based in. This may happen if any of our servers are from time to time located in a country other than the one you are based, or one of our vendors, partners, or service providers is located in a country other than one you are based in.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) By submitting your information and personal data to us, you agree to the transfer, storage, and processing of such information and personal data in the manner described above.
</MKTypography>

<MKTypography mt={4} variant="h6" color="white" opacity={1}>
10. DATA SECURITY 
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) We implement appropriate security measures and privacy-protective features on our Platform including encryption, password protection, call masking, and physical security measures to protect your personal data from unauthorized access and disclosure, and follow standards prescribed by applicable law.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) Where you have chosen a password that enables you to access certain parts of the Services or Expert Services, you are responsible for keeping this password secret and confidential. We will not be responsible for any unauthorized use of your information, or for any lost, stolen, or compromised passwords, or for any activity on your user account due to such unauthorized disclosure of your password. In the event your password has been compromised in any manner whatsoever, you should promptly notify us to enable us to initiate a change of password.
</MKTypography>

<MKTypography mt={4} variant="h6" color="white" opacity={1}>
11. DATA RETENTION
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) You agree and acknowledge that your personal data will continue to be stored and retained by us for as long as necessary to fulfill our stated purpose(s) and for a reasonable period after the termination of your account on the Platform or access to the Services to comply with our legal rights and obligations.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) In some circumstances, we may aggregate your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.
</MKTypography>

<MKTypography mt={4} variant="h6" color="white" opacity={1}>
12. BUSINESS TRANSITIONS 
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
You are aware that in the event we go through a business transition, such as a merger, acquisition by another organization, or sale of all or a portion of our assets, your personal data might be among the assets transferred.
</MKTypography>

<MKTypography mt={4} variant="h6" color="white" opacity={1}>
13. USER GENERATED CONTENT
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
We invite you to post content on our Platform, including your comments, feedback, pictures, or any other information that you would like to be made available on our Platform. Please note that such content will be available to all visitors to our Platform and may become public. We cannot prevent such information from being used in a manner that is contrary to this Policy, applicable laws, or your personal privacy, and we disclaim all liability (express or implied) in this regard. Further, you agree to comply with all applicable laws in relation to the content uploaded or otherwise shared by you on our Platform. You understand and acknowledge that you will be solely responsible for any information published by you on our Platform that violates applicable laws.
</MKTypography>



<MKTypography mt={4} variant="h6" color="white" opacity={1}>
14. Advertisements
</MKTypography>

<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) Ads appearing on our website may be delivered to users by advertising partners, who may set cookies.
</MKTypography>

<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer.
</MKTypography>

<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(c) This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you.
</MKTypography>

<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(d) This Privacy Policy covers the use of cookies by website and does not cover the use of cookies by any advertisers.
</MKTypography>












<MKTypography mt={4} variant="h6" color="white" opacity={1}>
15. UPDATES TO THIS POLICY
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(a) We may occasionally update this Policy. If we make changes to this Policy, we will upload the revised policy on the Platform or share it with you through other means, such as email. To the extent permitted under applicable law, by using our Platform after such notice, you consent to updates made to this Policy.
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
(b) We encourage you to periodically review this Policy for the latest information on our privacy practices.
</MKTypography>


<MKTypography mt={4} variant="h6" color="white" opacity={1}>
16. GRIEVANCE OFFICER
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
If you have any questions about this Policy, how we process or handle your personal data, or otherwise, you may reach out to us, with your queries, grievances, feedback, and comments at info@sweetexpertservice.com or contact our grievance officer whose contact details are provided below:
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
<u>Grievance Officers</u>
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
Name: Kritesh kabariya;
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
Designation: Director
</MKTypography>
<MKTypography mt={1} variant="body2" color="white" opacity={1}>
Email: info@sweetexpertservice.com
</MKTypography>

          </Grid>
        </Grid>
        
      </Container>
    </MKBox>
  );
}

export default Team;
