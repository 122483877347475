import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      borderRadius={5}
      py={6}
      mx={1.2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              TERMS AND CONDITIONS
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Last Updated: 29thApril, 2022
            </MKTypography>
            <MKTypography mt={6} variant="body2" color="white" opacity={1}>
              These terms and conditions <b>("Terms")</b> govern the use of
              services made available on or through
              https://sweetexpertservice.com and/or the sweet expert service
              Company mobile app (collectively, the <b>"Platform"</b>, and
              together with the services made available on or through the
              Platform, the <b>"Services")</b>. These Terms also include our
              privacy policy, available at
              https://sweetexpertservice.com/privacy-policy{" "}
              <b>("Privacy Policy")</b>, and any guidelines, additional, or
              supplemental terms, policies, and disclaimers made available or
              issued by us from time to time<b>("Supplemental Terms")</b>. The
              Privacy Policy and the Supplemental Terms form an integral part of
              these Terms. In the event of a conflict between these Terms and
              the Supplemental Terms with respect to applicable Services, the
              Supplemental Terms will prevail.
            </MKTypography>

            <MKTypography mt={6} variant="body2" color="white" opacity={1}>
              By using the Services, you agree that you have read, understood,
              and are bound by, these Terms, as amended from time to time, and
              that you will comply with the requirements listed here. These
              Terms expressly supersede any prior written agreements with you.
              If you do not agree to these Terms, or comply with the
              requirements listed here, please do not use the Services.
            </MKTypography>

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              1. SERVICES
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) The Platform is for your personal and non-commercial use only,
              unless otherwise agreed upon on in accordance with the terms of a
              separate agreement. Please note that the Platform is intended for
              use only within India. You agree that in the event you avail the
              Services or Pro Services from a legal jurisdiction other than the
              territory of India, you will be deemed to have accepted the sweet expert service
              terms and conditions applicable to that jurisdiction.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) The Services include the provision of the Platform that
              enables you to arrange and schedule different sweet-based services
              with independent third-party service providers of those services{" "}
              <b>("Expert Service")</b>. As a part of the Services,
              facilitates the transfer of payments to Expert Service for the
              services they render to you and collects payments on behalf of
              such hand to hand.
            </MKTypography>

            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) The Services are made available under various sweet experts
              who already know how to make them. There is no need for any
              certificate.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (d) The services rendered by Service Experts are referred to as{" "}
              Services. Sweet Expert Service does not provide the Pro Services and is not
              responsible for their provision. Service Experts are solely liable
              and responsible for the Services that they offer or otherwise
              provide through the Platform. Also Provide as Part-time provide
              service to bind there customer only provide to your
              sweet expert service for your making at home.
            </MKTypography>
            
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (e) In certain instances, you may be required to furnish
              identification proof to avail the Services,
              and hereby agree to do so. A failure to comply with this request
              may result in your inability to use the Services.
            </MKTypography>
            
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (f) A key part of the Services is sweet expert service's ability to send you text
              messages, electronic mails, or WhatsApp messages, including in
              connection with your bookings, your utilization of the Services,
              or as a part of its promotional and marketing strategies. While
              you may opt out of receiving these text messages by contacting sweet expert service
              at info@sweetexpertservice.com or through the in-Platform
              settings , you agree and acknowledge that this may impact sweet expert service's
              ability to provide the Services or a part of the Services to
              you.
            </MKTypography>

            
            <MKTypography
              mt={3}
              ml={2}
              variant="body2"
              color="white"
              opacity={1}
            >
              (g) <u>Sweet expert service Credits:</u>
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (i) This approach gives customers flexibility in how they make
              payments while ensuring that your company focuses solely on
              facilitating the service connection.
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (ii) It sounds like your company specializes in connecting
              customers with service experts who can provide sweet-making
              services directly at the customer's home. Your company facilitates
              this connection but doesn't handle the payment transaction for the
              service. Instead, customers and service providers arrange payment
              directly between themselves.
            </MKTypography>

            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (iii) Company is not responsible for any payment transactions.
            </MKTypography>

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              2. ACCOUNT CREATION
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) You are solely responsible for maintaining the security and
              confidentiality of your Account and agree to immediately notify us
              of any disclosure or unauthorized use of your Account or any other
              breach of security with respect to your Account.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) To avail the Services, you will be required to create an
              account on the Platform <b>("Account")</b>. For this Account, you
              may be required to furnish certain details, including but not
              limited to your phone number. To create an Account, you must be at
              least 18 years of age.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) You are liable and accountable for all activities that take
              place through your Account, including activities performed by
              persons other than you. We shall not be liable for any
              unauthorized access to your Account.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (d) You warrant that all information furnished in connection with
              your Account is and shall remain accurate and true. You agree to
              promptly update your details on the Platform in the event of any
              change to or modification of this information.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (e) You agree to receive communications from us regarding (i)
              requests for payments, (ii) information about us and the Services,
              (iii) promotional offers and services from us and our third party
              partners, and (iv) any other matter in relation to the Services.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (f) you must be provide your personal documentation who create business
              on our account once the account is approve you can start the provide your best service.
              your document is store very very confidential with high security storage with encrypted.
            </MKTypography>
        
           

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              3. USER CONTENT
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) You agree and acknowledge that UC may, without notice to you,
              remove, or otherwise restrict access to User Content that, in its
              sole discretion, violates these Terms.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) As part of the effective provision of the Services and quality
              control purposes, we may request reviews from you about Service
              Experts and you agree and acknowledge that Service Experts may
              provide reviews about you to us. You must not knowingly provide
              false, inaccurate, or misleading information in respect of the
              reviews. Reviews will be used by us for quality control purposes
              and to determine whether Customers and Service Expert are
              appropriate users of the Platform. If we determine at our sole
              discretion that you are not an appropriate user, we reserve the
              right to cancel your registration and remove you from our
              Platform.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) You grant us a non-exclusive, perpetual,transferable, worldwide, 
              irrevocable,  sublicensable, and royalty free licence
              to (i) use, host, display,publish, store, transfer, process,
              communicate, distribute, make available, modify, adapt, translate,
              and create derivative works of, the User Content, for the
              functioning of, and in connection with, the Services and (ii) use
              User Content for the limited purposes of advertising and promoting
              the Services, or furnishing evidence before a court or authority
              of competent jurisdiction under applicable laws.
            </MKTypography>

            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (d) Our Platform may contain interactive features or services that
              allow users who have created an account with us to post, upload,
              publish, display, transmit, or submit comments, reviews,
              suggestions, feedback, ideas, or other content on or through the
              Platform <b>("User Content")</b>.
            </MKTypography>
         
           <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (e) In connection with these Terms and the licences granted under
              this clause, you hereby waive any claims arising out of any moral
              rights or other similar rights relating to the User Content.
            </MKTypography>
           
           

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              4. CONSENT TO USE DATA
            </MKTypography>
           
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) Subject to applicable laws, we may be directed by law
              enforcement agencies or the government and related bodies to
              disclose data in relation to you in connection with criminal or
              civil proceedings. You understand and agree that in such instances
              we shall have the right to share such data with relevant agencies
              or bodies.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) In addition to any consent you may provide pursuant to the
              Privacy Policy, you hereby consent to us sharing your information
              with our affiliates or other third party service providers.We may
              use information and data pertaining to your use of the Services
              for provision of the Services, analytics, trend identification,
              and purposes of statistics to further enhance the effectiveness
              and efficiency of our Services, and provision of beneficial
              schemes, new offers, and for experience enhancement.
            </MKTypography>
           
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) You agree that we may, in accordance with our Privacy Policy,
              collect and use your personal data. The Privacy Policy is
              available at https://sweetexpertservice.com/privacy-policy and
              it explains the categories of personal data that we collect or
              otherwise process about you and the manner in which we process
              such data.
            </MKTypography>
            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              5. BOOKINGS
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) Confirmation: After submitting your request, you'll receive
              confirmation of the booking through a push notification. Once
              confirmed, an expert will arrive during your selected time slot to
              create your item based on the information provided. Payment
              settlement can be made at that time, giving you the opportunity to
              finalize the transaction conveniently.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) Orders: The Platform permits you to request various Services
              at a time of your choosing based on available slots. To make a
              booking, you should follow the instructions on the Platform and
              provide necessary information. We use reasonable efforts to enable
              you to find a Service Expert who is able to provide that service
              at the requested time. If, in the unlikely event we cannot find a
              Service Expert for the specific timeslot, we will contact you to
              find an alternative time.
            </MKTypography>
          
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) Cancellations: Bookings that are cancelled before confirmation
              on the Platform will not be charged. Sweet expert service's cancellation policy sets
              out applicable cancellation fees.
            </MKTypography>

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              6. PRICING, FEES, AND PAYMENT TERMS
            </MKTypography>
            
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) <u> Charges and Fees in respect of Services:</u>
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (i) Sweet Expert service shall notify you of the applicable Fees, Charges, and
              payment methods at the time of booking. Generally, you may make
              payments for Services through credit cards, debit cards, net
              banking, wallets, UPI or cash upon completion of the Service.
              We have the right to modify and otherwise restrict the modes of
              payment available to you. You acknowledge that certain payment
              methods such as cash upon completion may not always be available
              to you as a payment method. For the avoidance of doubt, in the
              event you pay through the method of 'cash upon completion', you
              acknowledge that you will be required to pay both Charges and Fees
              to the Service Expert.
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
            
              (ii) In respect of Pro Services that you seek to avail through the
              Platform, you shall be required to pay Service Expert the amount
              indicated at the time of booking as well as amounts towards (a)
              any additional Services you may avail, (b) out of pocket
              expenses incurred by the Service Expert, and (c) expenses arising
              out of the purchase of goods required or utilised for the
              performance of the Pro Service <b>("Charges")</b>. In addition to
              the Charges payable to Service Experts, sweet expert service reserves the right to
              charge you a convenience fee for facilitating the booking and
              transferring payments to the Service Expert (this fee is referred
              to as <b>"Fees")</b>. You acknowledge that the final bill you
              receive may include additional charges, including without
              limitation, a safety fee, warranty fee, insurance fee, or Service
              Expert welfare fee.
            </MKTypography>

            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (iii) Charges and Fees that you pay are final and non-refundable,
              unless otherwise determined by Sweet Expert Service or required by the applicable
              laws. Under certain laws, you may be entitled to a refund or other
              remedies for a failure in the provision of the Services.
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (iv) The Charges and Fees may be payable at the time upon the
              completion of the Service, as specified by Sweet Expert Service.
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (v) Taxes: All Charges and Fees are inclusive of applicable taxes.
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (vi) For the avoidance of doubt, please note that the Charges are
              payable to Service Experts, and Sweet Expert service acts as a limited collection
              agent on behalf of such Service Experts to collect and transfer
              amounts due to them.
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (vii) You acknowledge and agree that Charges and Fees applicable
              in certain geographical areas may increase substantially during
              times of high demand. Sweet Expert Service will use reasonable efforts to inform you
              of the Charges and Fees that may apply. However, by using the
              Services, you will be responsible for the Charges and Fees
              incurred under your Account regardless of your awareness of such
              Charges or Fees.
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (viii) Sweet Expert service reserves the right to reasonably amend the Charges and
              Fees at any time at its sole discretion. A change in Fees shall
              not impact any bookings that have been confirmed before the
              publication of the revised Fees on the Platform.
            </MKTypography>
           
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) Sweet Expert Service does not designate any portion of your payment as a tip or
              gratuity to the Service Expert. Any representation by Sweet Expert Service to the
              effect that tipping is "voluntary", "not required", and/or
              "included" in the payments you make for Services is not intended
              to suggest that Sweet Expert Service provides any additional payments to Service
              Expert. You understand and agree that while you are free to
              provide additional payment as a gratuity to any Service Expert who
              provides you with Services, you are under no obligation to do so.
              Gratuities are voluntary.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) Sweet expert service reserves the right to charge you for the different Services
              you may avail and/or for any other facilities you may opt for,
              from time to time, on or via the Platform.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (d) <u>Cancellation:</u> You may elect to cancel your request for
              services from a Sweet Expert Service at any time prior to such Service
              Expert arrival, in which case you may be charged a cancellation
              fee in accordance with Sweet Expert Service's cancellation policy. Sweet Expert Service reserves the
              right to charge you, or otherwise deduct applicable taxes in
              respect of such cancellation fee.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (f) <u>Payment Processors:</u> we are not connected with service
              payment provided by Application. Payment transaction done by
              Experts and you at your home. You can do it online or you
              can do it by cash. Company only provides the best expert near you.
            </MKTypography>

           
           

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              7. CUSTOMER CONDUCT
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) You agree that you will be liable for discriminating against
              Service Experts or for any failure, intentional or otherwise, to
              provide the Service Experts a safe, clean, and appropriate
              location for them to perform the Services. Additionally, you will
              also disclose any and all information that may have a bearing on
              the ability of the Service Expert to perform the Services or
              impact the Services Expert's health, safety, or well-being, to Sweet Expert Service
              and the Service Experts.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) Sweet Expert Service prohibits discrimination against Service Expert, including
              on the basis of race, religion, caste, national origin,
              disability, sexual orientation, sex, marital status, gender
              identity, age, or any other characteristic that not under
              applicable law.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) You agree that in the event a Service Expert behaves in a
              manner that is discourteous, disrespectful, abusive,
              inappropriate, or in violation of the law, you shall be required
              to report such incident to info@sweetexpertservice.com
              at the earliest but in any event within 48 (forty eight) hours of
              such incident.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (d) We request that you treat all Service Experts with courtesy
              and respect, and that you provide them with a safe, clean, and
              appropriate location to perform Services. Service Experts shall be
              entitled to refuse to perform Services if you have not provided a
              safe, clean, and appropriate location for them, or you behave
              towards them in a manner which is discourteous, disrespectful,
              abusive, or otherwise inappropriate. We reserve the right to
              withhold access to the Services and otherwise limit your access to
              Services at our absolute discretion if you behave towards any
              Service Expert in a manner which is discourteous, disrespectful,
              or abusive, or which we otherwise deem to be inappropriate or
              unlawful.
            </MKTypography>
          
          

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              8. THIRD PARTY SERVICES
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) We make no representations and exclude all warranties and
              liabilities arising out of or pertaining to such Third Party
              Services, including their accuracy or completeness. Should you
              avail a Third Party Service, you shall be governed and bound by
              the terms and conditions and privacy policy of the third parties
              providing the Third Party Services. Further, all intellectual
              property rights in and to Third Party Services are the property of
              the respective third parties.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) The Platform may include services, content, documents, and
              information owned by, licensed to, or otherwise made available by,
              a third party <b>("Third Party Services")</b> and contain links to
              Third Party Services. You understand and acknowledge that Third
              Party Services are the sole responsibility of the third party that
              created or provided it and that use of such Third Party Services
              is solely at your own risk.
            </MKTypography>
            

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              9. YOUR RESPONSIBILITIES
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) You represent and warrant that all information that you
              provide in relation to the Services is complete, true, and correct
              on the date of agreeing to these Terms and shall continue to be
              complete, true, and correct while you avail the Services. Should
              any information that you provide change during the existence of
              these Terms, you undertake to immediately bring such change to our
              notice. We do not accept any responsibility or liability for any
              loss or damage that you may suffer or incur if any information,
              documentation, material, or data, provided to avail the Services
              is incorrect, incomplete, inaccurate, or misleading or if you fail
              to disclose any material fact.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) You shall extend all cooperation to us in our defense of any
              proceedings that may be initiated against us due to a breach of
              your obligations or covenants under these Terms.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) In respect of the User Content, you represent and warrant
              that:
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (i) you are solely responsible for all activities that occur on
              or through your account on the Platform and all User Content;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (ii) you own all intellectual property rights (or have obtained all
              necessary permissions) to provide User Content and to grant the
              licences under these Terms;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (iii) the User Content does not and shall not violate, infringe, or
              misappropriate any intellectual property right or other
              proprietary right including the right of publicity or privacy of
              any person or entity;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (iv) the User Content does not and shall not violate any of your
              obligations or responsibilities under other agreements;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (v) the User Content does not and shall not violate any third
              party rights; and
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (vi) the User Content (A) does not belong to any other person to
              which you do not have any right, (B) does not threaten the unity,
              integrity, defence, security or sovereignty of India, friendly
              relations with foreign states, public order, cause incitement to
              the commission of any cognisable offence, prevents investigation
              of any offence, or is insulting another nation, (C) is not
              defamatory, grossly harmful, blasphemous, paedophilic, invasive of
              another's privacy, discriminatory based on gender, ethnically
              objectionable, disparaging, relating to, or encouraging money
              laundering or gambling, libellous, hateful, racist, violent,
              obscene, pornographic, unlawful, harmful to children, or (D)
              otherwise offensive, objectionable, or restricts, or inhibits, any
              other person from using or enjoying the Services.
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (vii) the User Content does not and shall not contain any viruses,
              corrupted data, or other harmful, disruptive, or destructive files
              or content;
            </MKTypography>
           
           
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (d) You shall not use the Services in any manner except as
              expressly permitted in these Terms. Without limiting the
              generality of the preceding sentence, you shall not:
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (i) use the Services to transmit any data, or send or upload any
              material that contains viruses, Trojan horses, worms, timebombs,
              keystroke loggers, spyware, adware, or any other harmful
              programmes, or similar computer code, designed to adversely affect
              the operation of any computer software or hardware;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (ii) infringe any proprietary rights, including but not limited to
              copyrights, patents, trademarks, or trade secrets of any party;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (iii) use any robot, spider, other automated device, or manual
              process to monitor or copy the Services or any portion thereof;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (iv) engage in the systematic retrieval of content from the
              Services to create or compile, directly or indirectly, a
              collection, compilation, database, or directory;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (v) except as may be provided hereunder, copy, display,
              distribute, modify, publish, reproduce, store, transmit, post,
              translate, create any derivative works from or license the
              Services;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (vi) decompile, reverse engineer, or disassemble the Services;
            </MKTypography>
          
           
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (vii) use the Services in (A) any unlawful manner, (B) for
              fraudulent or malicious activities or (C) in any manner
              inconsistent with these Terms;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (viii) violate applicable laws in any manner.
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (ix) link to, mirror, or frame, any portion of all or any of the
              Services; or
            </MKTypography>
           
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (e) You warrant that you shall not engage in any activity that
              interferes with or disrupts the Services.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (f) You shall not directly or indirectly, in any capacity,
              solicit, attempt to influence, engage, approach, or accept or
              encourage the solicitations or approach of, a Service Expert from
              whom you have availed Services, to either terminate or
              otherwise cease their registration on or engagement with the
              Platform, or avail services the same as or similar to the
              Services independently, without booking the Services through your
              Account. You agree that this limitation is reasonable and fair and
              is necessary for the protection of the privacy and security of
              Service Experts and that this will not preclude you from obtaining
              services the same as or similar to the Services through the
              Platform or other means. You further agree that any potential harm
              to Service Experts from the non-enforcement of this clause far
              outweighs any potential harm to you.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (g) You shall not attempt to gain unauthorised access to any
              portion or feature of the Services, any other systems or networks
              connected to the Services, to any of our servers, or through the
              Platform by hacking, password mining, or any other illegitimate
              means.
            </MKTypography>
           

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              11. DATA RETENTION
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) In some circumstances, we may aggregate your personal data (so
              that it can no longer be associated with you) for research or
              statistical purposes, in which case we may use this information
              indefinitely without further notice to you.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) You agree and acknowledge that your personal data will
              continue to be stored and retained by us for as long as necessary
              to fulfill our stated purpose(s) and for a reasonable period after
              the termination of your account on the Platform or access to the
              Services to comply with our legal rights and obligations.
            </MKTypography>
          

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              10. OUR INTELLECTUAL PROPERTY
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) Except as expressly stated in these Terms, nothing in these
              Terms should be construed as conferring any right in, or licence
              to, our or any third party's intellectual property rights.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) All rights, titles, and interest in, and to the Services,
              including all intellectual property rights arising out of the
              Services, are owned by or otherwise licensed to us. Subject to
              compliance with these Terms, we grant you a non-exclusive,
              non-transferable, non-sub licensable, revocable, and limited
              licence to use the Services in accordance with these Terms and our
              written instructions issued from time to time. Any rights not
              expressly granted herein are reserved by Sweet Expert Service or Sweet Expert Service's licensors.
            </MKTypography>

            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) We may request you to submit suggestions and other feedback,
              including bug reports, relating to the Services from time to time{" "}
              <b>("Feedback")</b>. We may freely use, copy, disclose, publish,
              display, distribute, and exploit the Feedback we receive from you
              without any payment of royalty, acknowledgement, prior consent, or
              any other form of restriction arising out of your intellectual
              property rights.
            </MKTypography>
            

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              11. TERM AND TERMINATION
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              We invite you to post content on our Platform, including your
              comments, feedback, pictures, or any other information that you
              would like to be made available on our Platform. Please note that
              such content will be available to all visitors to our Platform and
              may become public. We cannot prevent such information from being
              used in a manner that is contrary to this Policy, applicable laws,
              or your personal privacy, and we disclaim all liability (express
              or implied) in this regard. Further, you agree to comply with all
              applicable laws in relation to the content uploaded or otherwise
              shared by you on our Platform. You understand and acknowledge that
              you will be solely responsible for any information published by
              you on our Platform that violates applicable laws.
            </MKTypography>

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              14. UPDATES TO THIS POLICY
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) These Terms shall remain in effect unless terminated in
              accordance with the terms hereunder.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) You may terminate these Terms, at any time, for any reason by
              sending a notice to Sweet Expert Service at info@sweetexpertservice.com
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) We may restrict, deactivate, or terminate your access to, or
              use of, the Services, or any portion thereof, (i) immediately and
              at any point at our sole discretion, (A) if you violate or breach
              any of the obligations, responsibilities, or covenants under these
              Terms, (B) when you cease to become a user of our Services, (C)
              you do not, or are likely not to qualify under applicable law, or
              the standards and policies of UC or its affiliates, to access and
              use the Services, or (D) violate or breach the Community
              Guidelines, (ii) upon 30 (Thirty) days prior written notice to
              you, or (iii) immediately for any legitimate business, legal, or
              regulatory reason.
            </MKTypography>
            
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (d) Upon terminationof these Terms:
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (i) the Services will "time out" and
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (ii) the Account will expire;
            </MKTypography>
            
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (iii) these Terms shall terminate, except for those clauses that
              are expressly, or by implication, intended to survive termination
              or expiry.
            </MKTypography>

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              12. DISCLAIMERS AND WARRANTIES
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) While Sweet Expert Service strives to provide accurate information about
              Services and Charges, pricing errors may occur from time to time.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) The Services are provided on an "as is" basis without warranty
              of any kind, express, implied, statutory or otherwise, including
              without limitation the implied warranties of title,
              non-infringement, merchantability, or fitness for a particular
              purpose. Without limiting the foregoing, we make no warranty that
              the Services will meet your requirements or expectations.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) You agree and acknowledge that we are merely a Platform that
              connects you with Service Experts, and we shall not be liable in
              any manner for any obligations that have not been explicitly
              stated in these Terms. We are not liable or responsible for
              fulfilment of any bookings, for the performance of the Services by
              any Service Expert, or for any acts or omissions of the Service
              Experts during their provision of the Services including any
              damage they may cause to property. By booking Services through the
              Platform, you are entering into a contract with the relevant
              Service Provider for the provision of those services, and we
              accept no responsibility or liability, nor do we make any
              warranty, representation, or guarantee in respect of the Service
              Expert's performance under that contract.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (d) No advice or information, whether oral or written, obtained by
              you from us shall create any warranty that is not expressly stated
              in the Terms.
            </MKTypography>
           
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (e) We do not guarantee or warrant and we make no representation
              whatsoever regarding the reliability, quality, or suitability of
              the Service Experts.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (f) You agree and acknowledge that soliciting or receiving
              services from any Service Expert independently is solely at your
              own risk, and in such an event, you waive any rights that you may
              have under these Terms.
            </MKTypography>
           
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (g) You hereby accept full responsibility for any consequences
              that may arise from your use of the Services, and expressly agree
              and acknowledge that we shall have absolutely no liability in this
              regard.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (h) In no event shall Sweet Expert Service, its officers, directors, and employees,
              or its contractors, agents, licensors, partners, orsuppliers,be
              liable to you for any direct, special, indirect, incidental,
              consequential, punitive, reliance, or exemplary damages (including
              without limitation, lost business opportunities, lost revenues, or
              loss of anticipated profits or any other pecuniary or
              non-pecuniary loss or damage of any nature whatsoever, including
              but not limited to any abuse or breach of data), even if Sweet Expert Service or an
              authorised representative had been advised of the possibility of
              such damages, arising out of, or relating to (A) these Terms, (B)
              the Services or the Services, (C) your use or inability to use
              the Services, or (D) any other interactions with another user of
              the Services.
            </MKTypography>
           
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (i) To the fullest extent permissible by law, we, our affiliates,
              and our related parties, each disclaim all liability for any loss
              or damage arising out of, or due to:
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (ii) the occurrence or existence of any defect, interruption, or
              delays, in the operation or transmission of information to, from,
              or through the Services, communications failure, theft,
              destruction, or unauthorised access to our records, programmes,
              services, servers, or other infrastructure relating to the
              Services;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (iii) your use of, inability to use, or availability or
              unavailability of the Services;
            </MKTypography>
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (iv) the loss of any User Content and any other data in connection
              with your use of the Services.
            </MKTypography>
           
            <MKTypography
              mt={3}
              ml={4}
              variant="body2"
              color="white"
              opacity={1}
            >
              (v) the failure of the Services to remain operational for any
              period of time; and
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (vi) Sweet Expert Service will maintain a complaints management framework and will
              manage this framework on behalf of Service Experts in a reasonable
              manner and in accordance with the non-excludable requirements of
              relevant applicable laws.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (J) Nothing in these Terms will exclude or limit any warranty
              implied by law that it would be unlawful to exclude or limit.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (k) To the maximum extent permitted by law, our liability shall be
              limited to the amount of commission we receive in respect of a
              particular booking made on the Platform. In no event shall our
              total liability to you in connection with these Terms exceed INR
              10,000 (Rupees Ten Thousand).
            </MKTypography>
            

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              13. INDEMNITY
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              You shall indemnify, defend at our option, and hold us, our parent
              companies, subsidiaries, affiliates, and our officers, employees,
              directors, agents, and representatives, harmless from and against
              any claim, demand, lawsuits, judicial proceeding, losses,
              liabilities, damages, and costs (including, without limitation,
              all damages, liabilities, settlements, and attorneys' fees), due
              to or arising out of your access to the Services or Pro Services,
              use of the Services or Pro Services, violation of these Terms, or
              any violation of these Terms by any third party who may use your
              Account.
            </MKTypography>

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              14. JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) These Terms shall be governed by and construed and enforced in
              accordance with the laws of India. Subject to other provisions in
              this clause, courts in New Delhi shall have exclusive jurisdiction
              over all issues arising out of these Terms or the use of the
              Services.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) Any controversies, conflicts, disputes, or differences,
              arising out of these Terms shall be resolved by arbitration in New
              Delhi in accordance with the Arbitration and Conciliation Act,
              1996 for the time being in force, which is deemed to be
              incorporated by reference in this clause. The tribunal shall
              consist of 1 (One) arbitrator appointed by Sweet Expert Service. The language of the
              arbitration shall be English. The parties to the arbitration shall
              keep the arbitration confidential, and not disclose to any person,
              other than on a need to know basis, or to legal advisors, unless
              required to do so by law. The decision of the arbitrator shall be
              final and binding on all the parties thereto. Each party to the
              arbitration shall bear its own costs with respect to any dispute.
            </MKTypography>

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              15. GRIEVANCE REDRESSAL
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) You may contact our designated Grievance Redressal Officer
              with any complaints or queries relating to the Services or these
              Terms through registered post or through email, details of which
              are provided below:
            </MKTypography>

            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              Name: Kritesh kabariya
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              Designation:
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              Email Address: info@sweetexpertservice.com
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) We shall ensure that your complaint is resolved within
              timelines prescribed by applicable laws.
            </MKTypography>

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              16. MISCELLANEOUS PROVISIONS
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (a) Changes to Terms: The Terms are subject to revisions at any
              time, as determined by us, and all changes are effective
              immediately upon being posted on the Platform. It is your
              responsibility to review these Terms periodically for any updates
              or changes. You will be deemed to have accepted the changes made
              to these Terms if you continue to use the Platform once it has
              been posted.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (b) Modification to the Services: We reserve the right at any time
              to add, modify, or discontinue, temporarily or permanently, the
              Services (or any part thereof), with or without cause. We shall
              not be liable for any such addition, modification, suspension, or
              discontinuation of the Services.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (c) Severability: If any provision of these Terms is determined by
              any court or other competent authority to be unlawful or
              unenforceable, the other provisions of these Terms will continue
              to be in effect. If any unlawful or unenforceable provision would
              be lawful or enforceable if a part of it were deleted, that part
              will be deemed to be deleted, and the rest of the provision will
              continue in effect (unless that would contradict the clear
              intention of the clause, in which case the entirety of the
              relevant provision will be deemed to be deleted).
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (d) Assignment: You shall not license, sell, transfer, or assign
              your rights, obligations, or covenants under these Terms, or your
              Account in any manner without our prior written consent. We may
              grant or withhold this consent at our sole discretion, subject to
              any conditions we deem appropriate. We may assign our rights to
              any of our affiliates, subsidiaries, or parent companies, any
              successor in interest of any business associated with the
              Services, or any third party without any prior notice to you.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (e) Notices: All notices, requests, demands, and determinations
              for us under these Terms (other than routine operational
              communications) shall be sent to info@sweetexpertservice.com.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (f) Third Party Rights: No third party shall have any rights to
              enforce any terms contained herein.
            </MKTypography>
            <MKTypography mt={3} variant="body2" color="white" opacity={1}>
              (g) Force Majeure: We shall have no liability to you if we are
              prevented from or delayed in performing our obligations, or from
              carrying on our business, by acts, events, omissions, or accidents
              beyond our reasonable control, including without limitation,
              strikes, failure of a utility service or telecommunications
              network, act of God, war, riot, civil commotion, malicious damage,
              or compliance with any law or governmental order, rule,
              regulation, or direction.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
