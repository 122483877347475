import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      borderRadius={5}
      py={6}
      mx={1.2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Disclaimer
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Welcome to Sweet Expert service (“the Website”). By accessing or
              using the Website, you agree to comply with and be bound by the
              following disclaimer, terms, and conditions of use. If you
              disagree with any part of this disclaimer, you must not use this
              Website.
            </MKTypography>
            <MKTypography mt={4} variant="body1" color="white" opacity={1}>
              **Accuracy of Information**
            </MKTypography>

            <MKTypography mt={1} variant="body2" color="white" opacity={0.8}>
              This information provided on the website is for top 10 our sweet
              expert service provider and their details who provider best
              service in at other user who book their service. we will keep the
              information up to date and correct. so other can make book with
              trusted person and professional expert, we make no representations
              or warranties of any kind, express or implied, about the
              completeness, accuracy, reliability, suitability, or availability
              with respect to the Website or the information, products,
              services, or related graphics contained on the Website for any
              purpose. Any reliance you place on such information is therefore
              strictly at your own risk.
            </MKTypography>

            <MKTypography
              mt={1}
              variant="body1"
              color="white"
              opacity={1}
              fontSize={16}
            >
              Changes to the Disclaimer
            </MKTypography>
            <MKTypography mt={1} variant="body2" color="white" opacity={0.8}>
              We reserve the right to modify or replace this disclaimer at any
              time without prior notice. By continuing to access or use the
              Website after any revisions become effective, you agree to be
              bound by the revised terms. If you do not agree to the new terms,
              please stop using the Website.
            </MKTypography>

            <MKTypography
              mt={1}
              variant="body1"
              color="white"
              opacity={1}
              fontSize={16}
            >
              Contact Us
            </MKTypography>
            <MKTypography mt={1} variant="body2" color="white" opacity={0.8}>
              If you have any questions about this disclaimer, please contact us
              at info@sweetexpertservice.com.
            </MKTypography>

            <MKTypography mt={4} variant="h6" color="white" opacity={1}>
              GRIEVANCE OFFICER
            </MKTypography>
            <MKTypography mt={1} variant="body2" color="white" opacity={0.8}>
              If you have any questions about this Policy, how we process or
              handle your personal data, or otherwise, you may reach out to us,
              with your queries, grievances, feedback, and comments at
              info@sweetexpertservice.com or contact our grievance officer whose
              contact details are provided below:
            </MKTypography>
            <MKTypography mt={1} variant="body2" color="white" opacity={1}>
              <u>Grievance Officers</u>
            </MKTypography>
            <MKTypography mt={1} variant="body2" color="white" opacity={1}>
              Name: Kritesh kabariya;
            </MKTypography>
            <MKTypography mt={1} variant="body2" color="white" opacity={1}>
              Designation: Director
            </MKTypography>
            <MKTypography mt={1} variant="body2" color="white" opacity={1}>
              Email: info@sweetexpertservice.com
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
