import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
        <MKTypography mt={6} variant="body1" color="black" opacity={1}>
        Empowering Home-Based Story
          </MKTypography>

          <MKTypography mt={4} variant="body2" color="black" opacity={1}>
            In the heart of a bustling neighborhood, amidst the laughter and
            celebrations of festive days, lay a dilemma for Our's family. They
            yearned for the sweet indulgence of homemade treats, yet lacked the
            expertise to conjure them. The store-bought alternatives fell short,
            leaving them craving the authenticity of homemade delights.
          </MKTypography>

          <MKTypography mt={4} variant="body2" color="black" opacity={1}>
            Turning to their neighbor, Mrs. Gupta, renowned for her culinary
            prowess, Our's family sought guidance. Mrs. Gupta, with her warm
            smile and expertise in traditional recipes, became their beacon of
            hope in this sugary conundrum.
          </MKTypography>
          <MKTypography mt={4} variant="body2" color="black" opacity={1}>
            With a modest exchange of fees, Mrs. Gupta graciously agreed to
            share her culinary secrets. Under her guidance, Our's family delved
            into the art of crafting exquisite sweet dishes, from mouthwatering
            desserts to delectable confections.
          </MKTypography>
          <MKTypography mt={4} variant="body2" color="black" opacity={1}>
            As celebrations approached, Our's home transformed into a bustling
            kitchen, brimming with creativity and the aroma of freshly baked
            treats. Their guests were enthralled by the array of homemade
            delights, each bite a testament to Mrs. Gupta's guidance and Our's
            newfound skills.
          </MKTypography>
          <MKTypography mt={4} variant="body2" color="black" opacity={1}>
            However, amidst the joy of their successful venture, a thought
            lingered in Our's mind—a spark of inspiration that ignited a vision
            for empowerment.
          </MKTypography>
          <MKTypography mt={4} variant="body2" color="black" opacity={1}>
            Reflecting on their journey, Our envisioned a business opportunity
            that would not only cater to the cravings of others but also empower
            women in her community. With determination and foresight, she
            embarked on a mission to establish a platform for home-based
            entrepreneurs, offering them the opportunity to showcase their
            culinary talents and earn a livelihood.
          </MKTypography>
          <MKTypography mt={4} variant="body2" color="black" opacity={1}>
            Through her venture, Our sought to bridge the gap between homemade
            goodness and market demand, providing customers with access to
            authentic, healthy sweet dishes while empowering women to pursue
            entrepreneurship as a part-time endeavor.
          </MKTypography>
          <MKTypography mt={4} variant="body2" color="black" opacity={1}>
            With meticulous planning and unwavering determination, Our's vision
            became a reality. Her business, Sweet Ventures, flourished,
            attracting a loyal customer base eager to savor the delights of
            homemade treats.
          </MKTypography>
          <MKTypography mt={4} variant="body2" color="black" opacity={1}>
            Through Sweet Ventures, Our not only satisfied cravings but also
            fostered a sense of community and empowerment. Women from all walks
            of life found a platform to showcase their talents and contribute to
            their households, one sweet dish at a time.
          </MKTypography>
          <MKTypography mt={4} variant="body2" color="black" opacity={1}>
            As celebrations continued year after year, Sweet Ventures stood as a
            testament to the power of entrepreneurship and the transformative
            impact of homemade goodness. Our's vision had not only enriched the
            lives of her customers but had also empowered a generation of
            home-based entrepreneurs, turning sweet dreams into sweet realities.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
