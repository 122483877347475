// Import the necessary Firebase modules
import { initializeApp } from "firebase/app";


// Your Firebase config here
const firebaseConfig = {
  apiKey: 'AIzaSyBq-1xxjxDwTQTGuLma7JV-mk41hrAhGpA',
  authDomain: 'expoerts-sweets.firebaseapp.com',
  databaseURL: 'https://expoerts-sweets-default-rtdb.firebaseio.com',
  projectId: 'expoerts-sweets',
  storageBucket: 'expoerts-sweets.appspot.com',
  messagingSenderId: '19717108317',
  appId: '1:19717108317:web:c4588cf211310ca92853b4',
  measurementId: 'G-XTCXZWHLS4',
};

// Initialize Firebase
 const cong = initializeApp(firebaseConfig);
 

  export default cong;
// Now you can use Firebase services in your React app!