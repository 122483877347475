const imagesPrefix =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";

export default [
  {
    title: "Top Sweet Items",
    description: "A selection of Top 10 Sweet items. who's getting best feedback and review also book",
    items: [
      {
        image: `https://firebasestorage.googleapis.com/v0/b/expoerts-sweets.appspot.com/o/ExpertsImg%2F0.6046857815056922.JPG?alt=media&token=daf5c11d-d4a6-4c9f-9098-526faefbeb1e`,
        name: "Sweet Symphony",
        count: 10,
        route: "/SweetExpertDetail/S20nN9wAkgKqIztpN9pzmK",
        id: '0nN9wAkgKqIztpN9pzmK',
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/expoerts-sweets.appspot.com/o/ExpertsImg%2F0.22201029082358423.JPG?alt=media&token=f31a3fdf-b446-4fac-8813-49fcc88fbb81",
        name: "Sugar Plum Bakery",
        count: 14,
        route: "/SweetExpertDetail/k8zqwR2KTmOb3jhzMYv5A7",
        id: 'zqwR2KTmOb3jhzMYv5A7'
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/expoerts-sweets.appspot.com/o/ExpertsImg%2F0.43121549837346146.JPG?alt=media&token=9f2e7625-5503-4fe6-a074-7431ccb4c921",
        name: "Blissful Bites",
        count: 8,
        route: "/SweetExpertDetail/7gSKvGheEM5DpQehj5288D",
        id: 'SKvGheEM5DpQehj5288D',
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/expoerts-sweets.appspot.com/o/ExpertsImg%2F0.9984291134622735.JPG?alt=media&token=2e04880d-c3dc-4a89-93c2-60246733e839",
        name: "Sweet Sensations",
        count: 1,
        route: "/SweetExpertDetail/irig6uWeCVQvMhCTB2X2sJ",
        id:'ig6uWeCVQvMhCTB2X2sJ',
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/expoerts-sweets.appspot.com/o/ExpertsImg%2F0.6665890119404667.JPG?alt=media&token=b35ed106-7b5d-4b39-82fe-3a16f0993f54",
        name: "Neeta’s Sweet",
        count: 11,
        route: "/SweetExpertDetail/ixEI0GNfArplFKU4IZxzBw",
        id: 'EI0GNfArplFKU4IZxzBw',
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/expoerts-sweets.appspot.com/o/ExpertsImg%2F0.6490170697657147.JPG?alt=media&token=d847992b-2c37-4b39-8cd9-904c52e2c54c",
        name: "Sweet Delights",
        count: 11,
        route: "/SweetExpertDetail/o6Glih0xZBmNNrKx70ZQx9",
        id: "Glih0xZBmNNrKx70ZQx9",
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/expoerts-sweets.appspot.com/o/ExpertsImg%2F0.9918183436216076.JPG?alt=media&token=c0368c3d-881b-4eae-9fb6-f442c62998b2",
        name: "Sweet Tooth Delights",
        count: 6,
        route: "/SweetExpertDetail/ssS0CsWuCppZP1qJbxT3Th",
        id: 'S0CsWuCppZP1qJbxT3Th',
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/expoerts-sweets.appspot.com/o/ExpertsImg%2F0.5911331079166486.JPG?alt=media&token=5fdc0ed1-2839-47ad-a33b-5491215e15c0",
        name: "Sugary Delights",
        count: 3,
        route: "/SweetExpertDetail/0oUlD4Ny0fkhVAeYKuZwHN",
        id:'UlD4Ny0fkhVAeYKuZwHN',
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/expoerts-sweets.appspot.com/o/ExpertsImg%2F0.8336941696549812.JPG?alt=media&token=3e2ec65f-9947-459d-90f2-cbc3e1f79b4d",
        name: "Sweet Sensations",
        count: 8,
        route: "/SweetExpertDetail/pakOtEkaS9qOiIPviy3AaF",
        id:'kOtEkaS9qOiIPviy3AaF',
      },
      {
        image: "https://firebasestorage.googleapis.com/v0/b/expoerts-sweets.appspot.com/o/ExpertsImg%2F0.8916409330992812.JPG?alt=media&token=3e78c7e8-037f-4a1b-8a27-59002450e200",
        name: "Sugar Rush Bakery",
        count: 6,
        route: "/SweetExpertDetail/kcsY6PXXSAFgUjaegbpNYf",
        id: 'sY6PXXSAFgUjaegbpNYf'
      },
    ],
  },
  // {
  //   title: "Navigation",
  //   description: "30+ components that will help go through the pages",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/navbars.jpg`,
  //       name: "Navbars",
  //       count: 4,
  //       route: "/sections/navigation/navbars",
  //     },
  //     {
  //       image: `${imagesPrefix}/nav-tabs.jpg`,
  //       name: "Nav Tabs",
  //       count: 2,
  //       route: "/sections/navigation/nav-tabs",
  //     },
  //     {
  //       image: `${imagesPrefix}/pagination.jpg`,
  //       name: "Pagination",
  //       count: 3,
  //       route: "/sections/navigation/pagination",
  //     },
  //   ],
  // },
  // {
  //   title: "Input Areas",
  //   description: "50+ elements that you need for text manipulation and insertion",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/newsletters.jpg`,
  //       name: "Newsletters",
  //       count: 6,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/contact-sections.jpg`,
  //       name: "Contact Sections",
  //       count: 8,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/forms.jpg`,
  //       name: "Forms",
  //       count: 3,
  //       route: "/sections/input-areas/forms",
  //     },
  //     {
  //       image: `${imagesPrefix}/inputs.jpg`,
  //       name: "Inputs",
  //       count: 6,
  //       route: "/sections/input-areas/inputs",
  //     },
  //   ],
  // },
  // {
  //   title: "Attention Catchers",
  //   description: "20+ Fully coded components that popup from different places of the screen",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/alerts.jpg`,
  //       name: "Alerts",
  //       count: 4,
  //       route: "/sections/attention-catchers/alerts",
  //     },
  //     {
  //       image: `${imagesPrefix}/toasts.jpg`,
  //       name: "Notifications",
  //       count: 3,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/popovers.jpg`,
  //       name: "Tooltips & Popovers",
  //       count: 2,
  //       route: "/sections/attention-catchers/tooltips-popovers",
  //     },
  //     {
  //       image: `${imagesPrefix}/modals.jpg`,
  //       name: "Modals",
  //       count: 5,
  //       route: "/sections/attention-catchers/modals",
  //     },
  //   ],
  // },
  // {
  //   title: "Elements",
  //   description: "80+ carefully crafted small elements that come with multiple colors and shapes",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/buttons.jpg`,
  //       name: "Buttons",
  //       count: 6,
  //       route: "/sections/elements/buttons",
  //     },
  //     {
  //       image: `${imagesPrefix}/avatars.jpg`,
  //       name: "Avatars",
  //       count: 2,
  //       route: "/sections/elements/avatars",
  //     },
  //     {
  //       image: `${imagesPrefix}/dropdowns.jpg`,
  //       name: "Dropdowns",
  //       count: 2,
  //       route: "/sections/elements/dropdowns",
  //     },
  //     {
  //       image: `${imagesPrefix}/switch.jpg`,
  //       name: "Toggles",
  //       count: 2,
  //       route: "/sections/elements/toggles",
  //     },
  //     {
  //       image: `${imagesPrefix}/social-buttons.jpg`,
  //       name: "Social Buttons",
  //       count: 2,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/breadcrumbs.jpg`,
  //       name: "Breadcrumbs",
  //       count: 1,
  //       route: "/sections/elements/breadcrumbs",
  //     },
  //     {
  //       image: `${imagesPrefix}/badges.jpg`,
  //       name: "Badges",
  //       count: 3,
  //       route: "/sections/elements/badges",
  //     },
  //     {
  //       image: `${imagesPrefix}/progress.jpg`,
  //       name: "Progress Bars",
  //       count: 4,
  //       route: "/sections/elements/progress-bars",
  //     },
  //     {
  //       image: `${imagesPrefix}/tables.jpg`,
  //       name: "Tables",
  //       count: 3,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/typography.jpg`,
  //       name: "Typography",
  //       count: 2,
  //       route: "/sections/elements/typography",
  //     },
  //   ],
  // },
];
