import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={4}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2" mr={1}>Trusted by</MKTypography>
          
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            160+ users
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
          Our Sweet Expert Service Business has garnered admiration from a diverse range of clients.
          </MKTypography>
        </Grid>
        {/* <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Emily W."
              date="1 day ago"
              review="Absolutely delighted with the sweet expert service! Their attention to detail and dedication to quality truly set them apart. Every treat was a masterpiece of flavor and presentation. Highly recommend for anyone looking to elevate their sweet experience!"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Mark S."
              date="1 week ago"
              review="Impressed beyond words! The sweet expert service exceeded all expectations. From the first bite to the last, each delicacy was a burst of delight. The team's passion for their craft shines through in every aspect. Can't wait to order again!" 
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Sarah L."
              date="3 weeks ago"
              review="The sweet expert service has become my go-to for all celebrations. Not only are the sweets mouthwateringly delicious, but the convenience and professionalism of the service make every experience seamless. A definite must-try for anyone with a sweet tooth!"
              rating={5}
            />
          </Grid>
        </Grid> */}
        {/* <Divider sx={{ my: 6 }} /> */}
        {/* <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={appleLogo} alt="Apple" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={facebookLogo} alt="Facebook" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={nasaLogo} alt="Nasa" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={vodafoneLogo} alt="Vodafone" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={digitalOceanLogo}
              alt="DigitalOcean"
              width="100%"
              opacity={0.6}
            />
          </Grid>
        </Grid> */}
      </Container>
    </MKBox>
  );
}

export default Information;
